import styled from "styled-components";
import { CONTAINER, getMarginAsPixels, getMediaQuery, getUnitAsNumber, getUnitAsPixels, getVerticalSpacingAsStyle, ROWS_LAYOUT, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";
import { Container } from "../../common/container";

import Doorway from '../../../assets/doorway.inline.svg';
import { Link } from "gatsby";
import { pixelate } from "../../../theme/helpers";

export const StyledOuter = styled.div`
  min-height: 100vh;
  clip-path: inset(0 0 0 0);
  position: relative;
  z-index: 6;
  background-color: ${({ theme }) => theme.background.primary};
  display: flex;
  justify-content: center;
  padding: 0 ${getMarginAsPixels(ScreenSize.SMALL)};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    padding: 0 ${getMarginAsPixels(ScreenSize.MEDIUM)};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    padding: 0 ${pixelate(getUnitAsNumber({multiplier:5}) - 3)};
  }

  ${getMediaQuery(ScreenSize.XLARGE)} {
    padding: 0 ${pixelate(getUnitAsNumber({multiplier:5}) - 6)};
  }
`

export const StyledInner = styled.div`
  ${ROWS_LAYOUT}
  color: ${({ theme }) => theme.foreground.primary};
  padding: ${getUnitAsPixels({multiplier:3})} 0;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    padding: ${pixelate(getUnitAsNumber({multiplier:9}) - 3)} 0;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    padding: ${pixelate(getUnitAsNumber({multiplier:5}) - 3)} 0;
  }

  ${getMediaQuery(ScreenSize.XLARGE)} {
    padding: ${pixelate(getUnitAsNumber({multiplier:5}) - 6)} 0;
  }
`

export const FooterContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
`

export const Legal = styled.p`
  ${getTypography(Type.LABEL)}
  flex: 1 1 auto;
`

export const FooterLinks = styled.ul`
  ${getTypography(Type.LABEL)} 
  flex: 0 0 auto;
  display: flex;
  gap: ${getUnitAsPixels({multiplier:1.5})};

  a{
    color: ${({theme}) => theme.foreground.primary};
    text-decoration: none;

    @media (hover: hover) {
      transition: opacity 1s;
      &:hover{
        opacity: 0.7;
      }
    }
  }

  ${getMediaQuery(ScreenSize.XLARGE)} {
    gap: ${getUnitAsPixels({multiplier:2})};
  }
`

export const ContactContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Address = styled.div`
  ${getTypography(Type.IMPACT)}
  text-align: center;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN,{bottom:VerticalSpacingVariant.XSMALL})}
`

export const StyledHeading = styled.h2`
  ${getTypography(Type.HEADING)}
  text-align: center;
  margin-bottom: ${getUnitAsPixels({ multiplier: 1 })};
`

export const StyledText = styled.p`
  ${getTypography(Type.BODY_04)}
  text-align: center;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
`

export const ContactList = styled.ul`
  text-align: center;
  ${getTypography(Type.HEADING)}

  h3{
    margin-bottom: ${getUnitAsPixels({multiplier:1})};
  }
`

export const ContactLinks = styled.ul`
  ${getTypography(Type.LABEL)}

  a{
    color: ${({theme}) => theme.foreground.primary};
    text-decoration: none;

    @media (hover: hover) {
      transition: opacity 1s;
      &:hover{
        opacity: 0.7;
      }
    }
  }
`

export const HeaderContainer = styled.div`
  flex: 0 0 auto;
  display: none;

  @media screen and (min-width: 1200px){
    display: flex;
  }
`

export const StyledTitle = styled(Link)`
  ${getTypography(Type.LABEL)}
  color: ${({theme}) => theme.foreground.primary};
  flex: 1 1 auto;

  @media (hover: hover) {
    transition: opacity 1s;
    &:hover{
      opacity: 0.7;
    }
  }
`

export const HeaderLinks = styled.ul`
  ${getTypography(Type.LABEL)}
  flex: 0 0 auto;
  display: flex;
  gap: ${getUnitAsPixels({multiplier:1.5})};

  a{
    color: ${({theme}) => theme.foreground.primary};
    text-decoration-line: none;
    text-decoration-thickness: 1px;
    text-underline-offset: 5px;

    &.active{
      text-decoration-line: underline;
    }

    @media (hover: hover) {
      transition: opacity 1s;
      &:hover{
        opacity: 0.7;
      }
    }
  }

  ${getMediaQuery(ScreenSize.XLARGE)} {
    gap: ${getUnitAsPixels({multiplier:2})};
  }
`

export const StyledDoorway = styled(Doorway)`
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN,{bottom:VerticalSpacingVariant.XSMALL})}
`