import { Link } from "gatsby";
import styled from "styled-components";
import { Color, ColorTransform, getColor } from "../../../theme/color";
import { pixelate } from "../../../theme/helpers";
import { getMarginAsNumber, getMarginAsPixels, getMediaQuery, getUnitAsNumber, getUnitAsPixels, ScreenSize } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";

export const StyledContainer = styled.div<{$open:boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
  visibility: ${({$open}) => $open ? `visible` : `hidden`};
  backdrop-filter: blur(10px);

  @media screen and (min-width: 1200px){
    position: absolute;
    height: auto;
    visibility: visible;
    width: 100%;
    backdrop-filter: none;
  }
`

export const StyledContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${getUnitAsPixels({multiplier:8})} ${getMarginAsPixels(ScreenSize.SMALL)};
  background-color: ${getColor(Color.CREAM)};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    padding: ${getUnitAsPixels({multiplier:8})} ${getMarginAsPixels(ScreenSize.MEDIUM)};
    width: 50%;
  }

  @media screen and (min-width: 1200px){
    padding: ${pixelate(getUnitAsNumber({multiplier:5}) - 3)};
    background-color: transparent;
    flex-direction: row;
    height: auto;
    width: 100%;
  }

  ${getMediaQuery(ScreenSize.XLARGE)} {
    padding: ${pixelate(getUnitAsNumber({multiplier:5}) - 6)};
  }
`

export const StyledDoor = styled.svg`
   margin: 0 auto;
   margin-bottom: ${getUnitAsPixels({multiplier:10})};

   @media screen and (min-width: 1200px){
    display: none;
   }
`

export const CloseButton = styled.button`
  width: ${getUnitAsPixels({multiplier:5.5})};
  height: ${getUnitAsPixels({multiplier:5.5})};
  top: ${getUnitAsPixels({multiplier:7.5})};
  border: 2px solid ${getColor(Color.MID_GREEN)};
  position: fixed;
  z-index: 7;
  right: ${pixelate(getMarginAsNumber(ScreenSize.SMALL) - getUnitAsNumber({multiplier:0.5}))};
  border-radius: 50%;
  cursor: pointer;

  &:before, &:after{
    content: "";
    display: block;
    width: 26px;
    height: 2px;
    background-color: ${getColor(Color.MID_GREEN)};
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
  &:before{
    transform: translate(-50%,-50%) rotate(45deg);
  }

  &:after{
    transform: translate(-50%,-50%) rotate(-45deg);
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    top: ${getUnitAsPixels({multiplier:9})};
    right: ${pixelate(getMarginAsNumber(ScreenSize.MEDIUM) - getUnitAsNumber({multiplier:0.5}))};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    top: ${getUnitAsPixels({multiplier:5})};
    right: ${pixelate(getMarginAsNumber(ScreenSize.LARGE) - getUnitAsNumber({multiplier:0.5}))};
  }

  @media screen and (min-width: 1200px){
    display: none;
  }
  
`

export const StyledTitle = styled(Link)`
  ${getTypography(Type.LABEL)}
  color: ${getColor(Color.MID_GREEN)};
  text-align: center;
  flex: 0 0 auto;
  padding: ${getUnitAsPixels({multiplier:10})} 0 ${getUnitAsPixels({multiplier:5})} 0;
  margin: 0 ${getUnitAsPixels({multiplier:10})};

  @media screen and (min-width: 1200px){
    color: ${({theme}) => theme.foreground.primary};
    padding: 0;
    margin: 0;
  }

  @media (hover: hover) {
    transition: opacity 1s;
    &:hover{
      opacity: 0.7;
    }
  }
`

export const HeaderLinks = styled.ul`
  ${getTypography(Type.LABEL)}
  flex: 1 1 auto;
  display: flex;
  color: ${getColor(Color.MID_GREEN)};
  flex-direction: column;
  border-top: 1px solid ${ColorTransform(getColor(Color.MID_GREEN)).alpha(0.3)};

  li{
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${ColorTransform(getColor(Color.MID_GREEN)).alpha(0.3)};
  }

  a{
    color: ${getColor(Color.MID_GREEN)};
    text-decoration-line: none;
    text-decoration-thickness: 1px;
    text-underline-offset: 5px;

    &.active{
      text-decoration-line: underline;
    }

    @media (hover: hover) {
      transition: opacity 1s;
      &:hover{
        opacity: 0.7;
      }
    }
  }

  @media screen and (min-width: 1200px){
    flex-direction: row;
    color: ${({theme}) => theme.foreground.primary};
    border-top: none;
    gap: ${getUnitAsPixels({multiplier:1.5})};
    flex: 0 0 auto;

    li{
      border-bottom: none;
    }

    a{
      color: ${({theme}) => theme.foreground.primary};
    }
  }

  ${getMediaQuery(ScreenSize.XLARGE)} {
    gap: ${getUnitAsPixels({multiplier:2})};
  }
`