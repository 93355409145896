import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import { find, get, has } from 'lodash';
import React, { useEffect, MouseEvent, useState, useMemo, FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import { useLocation } from '@reach/router';

import { plainTextToHTML } from '../../../common/string';
import { Color, getTheme, Theme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { ContainerDisplay } from '../../common/container/container.types';
import { TextLink } from '../../common/textLink';
import { Address, ContactContainer, ContactLinks, ContactList, FooterContainer, FooterLinks, HeaderContainer, HeaderLinks, Legal, StyledDoorway, StyledHeading, StyledInner, StyledOuter, StyledText, StyledTitle } from './footer.styles';
import { Title } from '../../common/title';

export const Footer = () => {


  const data = useStaticQuery(graphql`
    query Footer {
      header: datoCmsGlobalHeader {
        links {
          ...LinkInternal
          ...LinkExternal
        }
        title
      }
      footer: datoCmsGlobalFooter {
        links {
          ...LinkInternal
          ...LinkExternal
          ...LinkFile
        }
        legal
      }
      contact: datoCmsPageContact {
        address
        text
      }
    }
  `);

  const header:Queries.DatoCmsGlobalHeader = data.header;
  const footer:Queries.DatoCmsGlobalFooter = data.footer;
  const contact:Queries.DatoCmsPageContact = data.contact;

  const location = useLocation();

  return (
    <ThemeProvider theme={getTheme(Theme.DARK_GREEN)}>
      <StyledOuter>
        <StyledInner>
          <Title color={Color.OFF_WHITE} atFront={true} showTitle={location.pathname === "/"} />
          {location.pathname !== "/contact/" && 
            <HeaderContainer>
              <StyledTitle to="/">{header.title}</StyledTitle>
              {header.links && 
                <HeaderLinks>
                  {header.links.map((link,index) => <li key={index}><TextLink link={link} /></li>)}
                </HeaderLinks>
              }
            </HeaderContainer>
          }

          <ContactContainer>
            <Address>{plainTextToHTML(contact.address)}</Address>
            <StyledDoorway />
            <StyledHeading>Contact</StyledHeading>
            <StyledText>{contact.text}</StyledText>
          </ContactContainer>

          <FooterContainer>
            <Legal>{footer.legal}</Legal>
            {footer.links && 
              <FooterLinks>
                {footer.links.map((link,index) => <li key={index}><TextLink link={link} /></li>)}
              </FooterLinks>
            }
          </FooterContainer>
        </StyledInner>
      </StyledOuter>
    </ThemeProvider>
  )
};
