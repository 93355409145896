export enum Font {
  Joan = 'Joan',
  WorkSans = 'WorkSans',
}

export const fonts: { [key in Font]: string } = {
  [Font.Joan]: `"Joan", serif`,
  [Font.WorkSans]: `"Work Sans", sans-serif`,
};

export const getFont = (font: Font): string => fonts[font];
