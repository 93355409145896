import styled, { css } from "styled-components"
import { Color, getColor } from "../../../theme/color"
import { Font, getFont } from "../../../theme/font"
import { getMarginAsPixels, getMediaQuery, getUnitAsPixels, getVerticalSpacingAsStyle, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout"

export const StyledEarls = styled.svg<{$color:Color,$atFront?:boolean,$visibleOnMobile?:boolean}>`
  fill: ${({$color}) => getColor($color)};
  ${({$atFront}) => $atFront ? `z-index: 4;` : ``}
  position: absolute;
  top: ${getUnitAsPixels({multiplier:8})};
  height: ${getUnitAsPixels({multiplier:5})};
  display: ${({$visibleOnMobile}) => $visibleOnMobile ? `block` : `none`};
  width: 100%;
  left: 0;
  pointer-events: none;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    top: ${getUnitAsPixels({multiplier:9})};
    height: ${getUnitAsPixels({multiplier:7})};
    display: block;
    position: fixed;
    width: 50%;
    left: 25%;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    top: ${getUnitAsPixels({multiplier:5})};
  }

  @media screen and (min-width: 1600px) {
    height: ${getUnitAsPixels({multiplier:10})};
  }
`

export const StyledTerrace = styled.svg<{$color:Color,$atFront?:boolean,$visibleOnMobile?:boolean}>`
  fill: ${({$color}) => getColor($color)};
  ${({$atFront}) => $atFront ? `z-index: 4;` : ``}
  position: absolute;
  bottom: ${getUnitAsPixels({multiplier:8})};
  height: ${getUnitAsPixels({multiplier:5})};
  display: ${({$visibleOnMobile}) => $visibleOnMobile ? `block` : `none`};
  width: 100%;
  left: 0;
  pointer-events: none;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    bottom: ${getUnitAsPixels({multiplier:9})};
    height: ${getUnitAsPixels({multiplier:7})};
    display: block;
    position: fixed;
    width: 50%;
    left: 25%;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    bottom: ${getUnitAsPixels({multiplier:5})};
  }

  @media screen and (min-width: 1600px) {
    height: ${getUnitAsPixels({multiplier:10})};
  }
`

export const StyledBurger = styled.button<{$color:Color,$atFront?:boolean}>`
  width: ${getUnitAsPixels({multiplier:4.5})};
  height: ${getUnitAsPixels({multiplier:3.5})};
  border-top: 2px solid ${({$color}) => getColor($color)};
  border-bottom: 2px solid ${({$color}) => getColor($color)};
  position: fixed;
  z-index: 6;
  top: ${getUnitAsPixels({multiplier:8.5})};
  right: ${getMarginAsPixels(ScreenSize.SMALL)};
  cursor: pointer;
  box-sizing: border-box;

  &:before{
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: ${({$color}) => getColor($color)};
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
  &:before{
    transform: translate(-50%,-50%);
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    top: ${getUnitAsPixels({multiplier:10.5})};
    right: ${getMarginAsPixels(ScreenSize.MEDIUM)};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    top: ${getUnitAsPixels({multiplier:6.5})};
    right: ${getMarginAsPixels(ScreenSize.LARGE)};
  }

  @media screen and (min-width: 1200px){
    display: none;
  }
  
`