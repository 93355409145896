import { get } from 'lodash';

export const isInternal = (url: string) => {
  // check against regex
  return /^\/(?!\/)/.test(url);
};

export const tidyURL = (url: string) => {
  // remove this domain
  const domain = process.env.URL ?? '';

  return url.replace(domain, '');
};

export const standardiseInternalURL = (url: string) => {
  return url.replace(/\/?(\?|#|$)/, '/$1').toLowerCase();
};

export const getPageHomePath = () => {
  return `/`;
};

export const getPageModularPath = ({
  slug,
}: {
  slug?: string;
}) => {
  return `/${slug ? `${slug}/` : ''}`;
};

export const getPageContactPath = () => {
  return `/contact/`;
};

export const getPageLegalPath = ({
  slug,
}: {
  slug?: string;
}) => {
  return `/legal/${slug ? `${slug}/` : ''}`;
};

export const getInternalURL = ({
  pageType,
  slug,
}: {
  pageType: string;
  slug?: string;
}): string => {
  switch (pageType) {
    case 'page_home':
      return getPageHomePath();
    case 'page_contact':
      return getPageContactPath();
    case 'page_modular':
      return getPageModularPath({ slug });
    case 'page_legal':
      return getPageLegalPath({ slug });
    default:
      return '/';
  }
};

export const getInternalURLFromPage = ({
  page,
}: {
  page:{
    model: { apiKey: string };
  }
}): string => {
  return getInternalURL({ pageType: get(page.model, 'apiKey'), slug:page.slug });
};

export const getAbsoluteURL = ({
  url,
  pageType,
  slug,
}: {
  url: string;
  pageType: string;
  slug?: string;
}) => {
  return `${url}${getInternalURL({ pageType, slug })}`;
};

export const removeTrailingSlash = (path: string) => {
  return path.length > 1 ? path.replace(/\/$/, '') : path;
};

export const getEmailURL = (address: string) => {
  return `mailto:${address}`;
};

export const getPhoneURL = (phone: string) => {
  return `tel:${phone.replace(/\s+/g, '')}`;
};
