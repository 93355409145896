export {default as ColorTransform} from 'color';

export enum Color {
  OFF_WHITE = 'OFF_WHITE',
  CREAM = 'CREAM',
  MID_GREEN = 'MID_GREEN',
  DARK_GREEN = 'DARK_GREEN',
}

export enum Theme {
  WHITE = 'WHITE',
  MID_GREEN = 'MID_GREEN',
  CREAM = 'CREAM',
  DARK_GREEN = 'DARK_GREEN',
}

export type ThemeType = {
  background: {
    primary: string;
  };
  foreground: {
    primary: string;
    secondary: string;
  };
};

const colors: { [key in Color]: string } = {
  [Color.OFF_WHITE]: '#FBFBFB',
  [Color.CREAM]: '#FFECD0',
  [Color.MID_GREEN]: '#51654E', 
  [Color.DARK_GREEN]: '#3A4838',
};

export const getColor = (color: Color): string => colors[color];

const themes: { [key in Theme]: ThemeType } = {
  [Theme.WHITE]: {
    background: {
      primary: getColor(Color.OFF_WHITE),
    },
    foreground: {
      primary: getColor(Color.MID_GREEN),
      secondary: getColor(Color.DARK_GREEN),
    },
  },
  [Theme.MID_GREEN]: {
    background: {
      primary: getColor(Color.MID_GREEN),
    },
    foreground: {
      primary: getColor(Color.OFF_WHITE),
      secondary: getColor(Color.CREAM),
    },
  },
  [Theme.CREAM]: {
    background: {
      primary: getColor(Color.CREAM),
    },
    foreground: {
      primary: getColor(Color.MID_GREEN),
      secondary: getColor(Color.DARK_GREEN),
    },
  },
  [Theme.DARK_GREEN]: {
    background: {
      primary: getColor(Color.DARK_GREEN),
    },
    foreground: {
      primary: getColor(Color.OFF_WHITE),
      secondary: getColor(Color.CREAM),
    },
  },
};

export const getTheme = (theme: Theme): ThemeType =>
  themes[theme] || themes[Theme.WHITE];
