import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import { find, get, has } from 'lodash';
import React, { useEffect, MouseEvent, useState, useMemo, FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import { useLocation } from '@reach/router';

import { getTheme, Theme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { ContainerDisplay } from '../../common/container/container.types';
import { TextLink } from '../../common/textLink';
import { CloseButton, HeaderLinks, StyledContainer, StyledContent, StyledDoor, StyledTitle } from './header.styles';
import { subscribe, unsubscribe } from '../../../utils/events';

export const Header = () => {
  const [open, setOpen] = useState(false);
  
  const data = useStaticQuery(graphql`
    query Header {
      header: datoCmsGlobalHeader {
        links {
          ...LinkInternal
          ...LinkExternal
        }
        title
      }
    }
  `);

  useEffect(() => {
    subscribe("burgerClick", () => setOpen(true));

    return () => {
      unsubscribe("burgerClick", () => setOpen(true));
    }
  }, []);

  const header:Queries.DatoCmsGlobalHeader = data.header;

  const location = useLocation();

  const handleCloseButtonClick = () => {
    setOpen(false);
  }

  useEffect(() => {
    setOpen(false);
  },[location]);

  return (
    <ThemeProvider theme={getTheme(location.pathname === "/" || location.pathname === "/contact/" ? Theme.MID_GREEN : Theme.WHITE)}>
      <StyledContainer $open={open}>
        <StyledContent>
          <CloseButton onClick={handleCloseButtonClick} />
          <StyledTitle to="/">{header.title}</StyledTitle>
          <StyledDoor width="51" height="101" viewBox="0 0 51 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5 17.5059L25.5 1.1837" stroke="#51654E" strokeWidth="1.5"/>
            <path d="M20.1094 17.7383L12.234 6.34085" stroke="#51654E" strokeWidth="1.5" strokeLinecap="square"/>
            <path d="M30.4453 18.1602L38.3207 6.76272" stroke="#51654E" strokeWidth="1.5" strokeLinecap="round"/>
            <line x1="0.75" y1="-0.75" x2="15.0983" y2="-0.75" transform="matrix(-0.822995 -0.568048 0.594423 -0.804153 17.8242 22.5459)" stroke="#51654E" strokeWidth="1.5" strokeLinecap="round"/>
            <line x1="0.75" y1="-0.75" x2="15.0983" y2="-0.75" transform="matrix(0.822996 -0.568048 -0.594422 -0.804153 32.7305 22.5459)" stroke="#51654E" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M49.5 26.0003C49.5 22.7986 48.8792 19.4694 47.6731 16.5115C46.467 13.5535 44.6992 10.8658 42.4706 8.60184C40.242 6.33789 37.5962 4.54204 34.6844 3.3168C31.7726 2.09156 28.6517 1.46094 25.5 1.46094C22.3483 1.46094 19.2274 2.09156 16.3156 3.3168C13.4038 4.54203 10.758 6.33789 8.52943 8.60183C6.30083 10.8658 4.533 13.5535 3.32689 16.5114C2.12077 19.4694 1.5 22.7986 1.5 26.0003" stroke="#51654E" strokeWidth="1.5"/>
            <path d="M34.5 26.0002C34.5 24.8848 34.2672 23.7802 33.8149 22.7496C33.3626 21.719 32.6997 20.7826 31.864 19.9938C31.0282 19.205 30.0361 18.5793 28.9442 18.1525C27.8522 17.7256 26.6819 17.5059 25.5 17.5059C24.3181 17.5059 23.1478 17.7256 22.0558 18.1525C20.9639 18.5793 19.9718 19.205 19.136 19.9938C18.3003 20.7826 17.6374 21.719 17.1851 22.7496C16.7328 23.7802 16.5 24.8847 16.5 26.0002" stroke="#51654E" strokeWidth="1.5"/>
            <path d="M42.5 26.0005C42.5 23.7695 42.0603 21.5603 41.206 19.4992C40.3516 17.438 39.0994 15.5652 37.5208 13.9876C35.9422 12.4101 34.0682 11.1587 32.0056 10.3049C29.9431 9.45115 27.7325 9.01172 25.5 9.01172C23.2675 9.01172 21.0569 9.45115 18.9944 10.3049C16.9318 11.1587 15.0578 12.4101 13.4792 13.9876C11.9006 15.5652 10.6484 17.438 9.79405 19.4992C8.93972 21.5603 8.5 23.7695 8.5 26.0005" stroke="#51654E" strokeWidth="1.5"/>
            <path d="M1.25785 100.245L1.49026 25.6169L49.5162 25.6169L49.2079 100.245H1.25785Z" stroke="#51654E" strokeWidth="1.47983"/>
          </StyledDoor>
          {header.links && 
            <HeaderLinks>
              {header.links.map((link,index) => <li key={index}><TextLink link={link} /></li>)}
            </HeaderLinks>
          }
        </StyledContent>
      </StyledContainer>
    </ThemeProvider>
  )
};
