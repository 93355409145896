import { graphql, useStaticQuery } from 'gatsby';
import { ParallaxProvider } from 'react-scroll-parallax';
import React, { ReactNode, FunctionComponent, memo } from 'react';
import { useLocation } from '@reach/router';

import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from 'styled-components';

import { DictionaryProvider } from '../../../context/dictionary';
import { LocaleProvider } from '../../../context/locale';
import '../../../theme/fonts/stylesheet.css';
import { getTheme, Theme, Color } from '../../../theme/color';
import { ErrorMessage } from '../../feedback/errorMessage';
import { Footer } from '../footer';
import { Header } from '../header';

import { Container, GlobalStyle } from './page.styles';


interface LayoutPageProps {
  children?: ReactNode;
}

const Page: FunctionComponent<LayoutPageProps> = ({
  children,
}) => {
  const data = useStaticQuery(graphql`
    query Page {
      dictionary: datoCmsGlobalDictionary {
        headingNotFound
        textNotFound
        headingError
      }
      site: datoCmsSite {
        locales
      }
    }
  `);

  const dictionary = data.dictionary;
  const site = data.site;
  
  if (!site || !dictionary) {
    return null;
  }

  const locales = site.locales as [string];
  const defaultLocale = site.locales[0];

  const ErrorPage = memo(({ error }: { error: Error }) => (
    <Container>
      <ErrorMessage heading={dictionary?.headingError} text={error.message} />
    </Container>
  ));

  const location = useLocation();

  console.log(location);

  return (
    <ThemeProvider
      theme={
        getTheme(Theme.WHITE)
      }
    >
      <DictionaryProvider dictionary={dictionary}>
        <GlobalStyle />
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <Header />
          <ParallaxProvider>
            <Container>{children}</Container>
          </ParallaxProvider>
          <Footer />
        </ErrorBoundary>
      </DictionaryProvider>
    </ThemeProvider>
  );
};

export default Page;
