import { get, has } from "lodash";
import React from "react";
import { Link as GatsbyLink } from 'gatsby';
import { TextLinkProps } from "./textLink.types";
import { getInternalURLFromPage } from "../../../common/link";

export const TextLink = ({
  className,
  link,
  partiallyActive
}:TextLinkProps) => {

  if(has(link,"page")){
    return <GatsbyLink className={className} to={getInternalURLFromPage({page:get(link,"page")})} activeClassName="active" partiallyActive={partiallyActive}>{get(link,"label")}</GatsbyLink>;
  }
  else if(has(link,"url")){
    return <a className={className} href={get(link,"url")} rel="noopener" target="_blank">{get(link,"label")}</a>
  }
  else if(has(link,"email")){
    return <a className={className} href={"mailto:" + get(link,"email")}>{get(link,"label")}</a>
  }
  else if(has(link,"phone")){
    return <a className={className} href={"tel:" + get(link,"phone")}>{get(link,"label")}</a>
  }
  else if(has(link,"file")){
    return <a className={className} href={get(link,"file.url")}>{get(link,"label")}</a>
  }

  return null;
};