import React, { Fragment } from 'react';

export const plainTextToHTML = (text?: string | null) => {
  if (text) {
    return text.split('\n\n').map((paragraph: string, index:number) => (
      <p key={index}>
        {paragraph
          .split('\n')
          .map((line: string, lineIndex: number, array: Array<string>) => (
            <Fragment key={lineIndex}>
              {line}
              {lineIndex < array.length - 1 && <br />}
            </Fragment>
          ))}
      </p>
    ));
  }

  return null;
};

export const snakeToCamelCase = (str: string) =>
  str.replace(/_([a-z])/g, (g) => {
    return g[1].toUpperCase();
  });
