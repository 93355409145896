import styled, { css } from 'styled-components';

import { Font, getFont } from './font';
import { getMediaQuery, ScreenSize, getUnitAsPixels } from './layout';

export enum Type {
  LABEL = 'LABEL',
  HEADING = 'HEADING',
  BODY_01 = 'BODY_01',
  BODY_02 = 'BODY_02',
  BODY_03 = 'BODY_03',
  BODY_04 = 'BODY_04',
  IMPACT = 'IMPACT',
}

const typography: { [key in Type]: any } = {
  LABEL: css`
    font-family: ${getFont(Font.WorkSans)};
    font-weight: 400;
    line-height: 1.4;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 15px;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 12px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)} {
      font-size: 16px;
    }
  `,
  HEADING: css`
    font-family: ${getFont(Font.WorkSans)};
    font-weight: 500;
    line-height: 1.4;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 15px;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 12px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)} {
      font-size: 16px;
    }
  `,
  IMPACT: css`
    font-family: ${getFont(Font.Joan)};
    font-size: 26px;
    letter-spacing: 0.18em;
    line-height: 1.4;
    text-decoration: none;
    text-transform: uppercase;
  `,
  BODY_01: css`
    font-family: ${getFont(Font.Joan)};
    font-size: 22px;
    line-height: 1.4;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 19px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)} {
      font-size: 32px;
    }
  `,
  BODY_02: css`
    font-family: ${getFont(Font.Joan)};
    font-size: 20px;
    line-height: 1.3;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 16px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)} {
      font-size: 22px;
    }
  `,
  BODY_03: css`
    font-family: ${getFont(Font.Joan)};
    font-size: 19px;
    line-height: 1.3;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 14px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)} {
      font-size: 22px;
    }
  `,
  BODY_04: css`
    font-family: ${getFont(Font.Joan)};
    font-size: 16px;
    line-height: 1.3;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 13px;
    }

    ${getMediaQuery(ScreenSize.XLARGE)} {
      font-size: 19px;
    }
  `,
};

export const getTypography = (type: Type): any => typography[type];

export const getTypographyComponent = (type: Type): any =>
  styled.div`
    ${getTypography(type)}
  `;
